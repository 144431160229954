import React from 'react';
import { hydrate, render } from "react-dom";
// import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.scss';
import GlobalStyles from './components/common/GlobalStyles';
// import i18n (needs to be bundled ;)) 
import './i18n';
import { BrowserRouter} from "react-router-dom";
import  {HelmetProvider } from 'react-helmet-async';


const MyApp = (
  <React.StrictMode>
    <HelmetProvider>
      <GlobalStyles />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(MyApp, rootElement);
} else {
  render(MyApp, rootElement);
}

// ReactDOM.render(,
//  document.getElementById('root')
// );