import {createGlobalStyle } from 'styled-components';
import GlobalSettings from './GlobalSettings';

const GlobalStyles = createGlobalStyle `
  html, body {
    background: ${GlobalSettings.mainWhiteColor};
    max-width: 2000px;
    font-family: Inter var,-apple-system,blinkmacsystemfont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 500;
    /* -webkit-font-smoothing: antialiased; */
    /* -webkit-tap-highlight-color: transparent; */ 
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-family: Larsseit,Inter var,-apple-system,blinkmacsystemfont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 700;
    margin-top: ${GlobalSettings.marginAroundHTMLElements};
    margin-bottom: ${GlobalSettings.marginAroundHTMLElements};
    font-size: 40pt;

    @media screen and (max-width: 1023px) {
      font-size: 34pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 28pt;
    }

    @media screen and (max-width: 480px) {
      font-size: 22pt;
    }
  }


  h2 {
    font-family: Larsseit,Inter var,-apple-system,blinkmacsystemfont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 700;
    margin-top: ${GlobalSettings.marginAroundHTMLElements};
    margin-bottom: ${GlobalSettings.marginAroundHTMLElements};
    font-size: 40pt;

    @media screen and (max-width: 1023px) {
      font-size: 34pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 28pt;
    }

    @media screen and (max-width: 480px) {
      font-size: 22pt;
    }

  }

  h3 {
    font-family: Larsseit,Inter var,-apple-system,blinkmacsystemfont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 700;
    margin-top: ${GlobalSettings.marginAroundHTMLElements};
    margin-bottom: ${GlobalSettings.marginAroundHTMLElements};
    font-size: 14pt;
    
    @media screen and (max-width: 1023px) {
      font-size: 12pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 11pt; 
    }

    @media screen and (max-width: 480px) {
      font-size: 10pt;
    }
  }

  h4 {
    font-family: Larsseit,Inter var,-apple-system,blinkmacsystemfont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 700;
    margin-top: ${GlobalSettings.marginAroundHTMLElements};
    margin-bottom: ${GlobalSettings.marginAroundHTMLElements};
    font-size: 34pt;

    @media screen and (max-width: 1023px) {
      font-size: 28pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 22pt;
    }

    @media screen and (max-width: 480px) {
      font-size: 16pt;
    }
  }

  h5 {
    font-family: Larsseit,Inter var,-apple-system,blinkmacsystemfont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 34pt;

    @media screen and (max-width: 1023px) {
      font-size: 28pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 22pt;
    }

    @media screen and (max-width: 480px) {
      font-size: 16pt;
    }
  }

  p, a, b, span, Link, button, li {
    font-family: Inter var,-apple-system,blinkmacsystemfont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    /* -webkit-font-smoothing: antialiased; */
    font-weight: 500;
    margin-top: ${GlobalSettings.marginAroundHTMLElements};
    margin-bottom: ${GlobalSettings.marginAroundHTMLElements};
    text-decoration: none;

    font-size: 12pt;
    
    @media screen and (max-width: 1023px) {
      font-size: 12pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 11pt; 
    }

    @media screen and (max-width: 480px) {
      font-size: 10pt;
    }
  }
`;

export default GlobalStyles; 

