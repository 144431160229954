import React, { Suspense } from 'react';
import {Switch, Route, Redirect } from "react-router-dom";

import ScrollToTop from './components/common/ScrollToTop';

// import Footer from './components/common/Footer/Footer';
// import FooterEnd from './components/common/Footer/FooterEnd';
// import HeaderTabletSmarphone from './components/common/Header/HeaderTabletSmarphone';
// import HeaderDesktop from './components/common/Header/HeaderDesktop';

const Footer = React.lazy(() => import('./components/common/Footer/Footer'));
const FooterEnd = React.lazy(() => import('./components/common/Footer/FooterEnd'));
const HeaderTabletSmarphone = React.lazy(() => import('./components/common/Header/HeaderTabletSmarphone'));
const HeaderDesktop = React.lazy(() => import('./components/common/Header/HeaderDesktop'));

// import Team from './pages/Team';
// import SolutionCleanAgency from './pages/SolutionCleanAgency';
// import SolutionDigitalCosmetics from './pages/SolutionDigitalCosmetics';

// import Home from './pages/Home';
// import About from './pages/About';
// import History from './pages/History';
// import TermsOfUse from './pages/TermsOfUse';
// import PrivacyStatements from './pages/PrivacyStatements';
// import CookieStatements from './pages/CookieStatements';
// import ResearchDigitalMarketing from './pages/ResearchDigitalMarketing';
// import ResearchManagement from './pages/ResearchManagement';
// import ResearchWebTechnologies from './pages/ResearchWebTechnologies';
// import InternshipProgram from './pages/InternshipProgram';
// import TrainingDigitalMarketing from './pages/TrainingDigitalMarketing';
// import TrainingMicrosoftOffice from './pages/TrainingMicrosoftOffice';
// import TrainingSearchEngineOptimization from './pages/TrainingSearchEngineOptimization';
// import TrainingWebDevelopmentBackend from './pages/TrainingWebDevelopmentBackend';
// import TrainingWebDevelopmentFrontend from './pages/TrainingWebDevelopmentFrontend';
// import ConsultingCenter from './pages/ConsultingCenter';

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const History = React.lazy(() => import('./pages/History'));
const TermsOfUse = React.lazy(() => import('./pages/TermsOfUse'));
const PrivacyStatements = React.lazy(() => import('./pages/PrivacyStatements'));
const CookieStatements = React.lazy(() => import('./pages/CookieStatements'));
const ResearchDigitalMarketing = React.lazy(() => import('./pages/ResearchDigitalMarketing'));
const ResearchManagement = React.lazy(() => import('./pages/ResearchManagement'));
const ResearchWebTechnologies = React.lazy(() => import('./pages/ResearchWebTechnologies'));
const InternshipProgram = React.lazy(() => import('./pages/InternshipProgram'));
const TrainingDigitalMarketing = React.lazy(() => import('./pages/TrainingDigitalMarketing'));
const TrainingMicrosoftOffice = React.lazy(() => import('./pages/TrainingMicrosoftOffice'));
const TrainingSearchEngineOptimization = React.lazy(() => import('./pages/TrainingSearchEngineOptimization'));
const TrainingWebDevelopmentBackend = React.lazy(() => import('./pages/TrainingWebDevelopmentBackend'));
const TrainingWebDevelopmentFrontend = React.lazy(() => import('./pages/TrainingWebDevelopmentFrontend'));
const ConsultingCenter = React.lazy(() => import('./pages/ConsultingCenter'));
const SupportForTrainingInGermany = React.lazy(() => import('./pages/SupportForTrainingInGermany'));
const SolutionDigitalCosmetics = React.lazy(() => import('./pages/SolutionDigitalCosmetics'));

const App = () => {
  return (
    <Suspense fallback={<div>loading...</div>}>
        <header>
          <ScrollToTop/>
          <HeaderDesktop/>
          <HeaderTabletSmarphone />
        </header>
        <section>
          <Switch>
            <Route exact path= "/" component={Home} />
            <Route exact path="/en/home" component={Home} />
            <Route exact path="/fr/accueil" component={Home} />
            <Route exact path="/en/about-the-rdct" component={About} />
            <Route exact path="/fr/a-propos-du-rdct" component={About} />
            <Route exact path="/en/history" component={History} />
            <Route exact path="/fr/histoire" component={History} />
            <Route exact path="/en/research-web-technologies" component={ResearchWebTechnologies} />
            <Route exact path="/fr/recherche-technologies-web" component={ResearchWebTechnologies} />
            <Route exact path="/en/research-digital-marketing" component={ResearchDigitalMarketing} />
            <Route exact path="/fr/recherche-marketing-digitale" component={ResearchDigitalMarketing} />
            <Route exact path="/en/research-management" component={ResearchManagement} />
            <Route exact path="/fr/recherche-management" component={ResearchManagement} />
            <Route exact path="/en/internship-program" component={InternshipProgram} />
            <Route exact path="/fr/programme-stages" component={InternshipProgram} />
            <Route exact path="/en/training-web-development-frontend" component={TrainingWebDevelopmentFrontend} />
            <Route exact path="/fr/formation-developpement-web-frontend" component={TrainingWebDevelopmentFrontend} />
            <Route exact path="/en/training-web-development-backend" component={TrainingWebDevelopmentBackend} />
            <Route exact path="/fr/formation-developpement-web-backend" component={TrainingWebDevelopmentBackend} />
            <Route exact path="/en/training-search-engine-optimization" component={TrainingSearchEngineOptimization} />
            <Route exact path="/fr/formation-search-engine-optimization" component={TrainingSearchEngineOptimization} />
            <Route exact path="/en/training-digital-marketing" component={TrainingDigitalMarketing} />
            <Route exact path="/fr/formation-marketing-digitale" component={TrainingDigitalMarketing} />
            <Route exact path="/en/training-microsoft-office" component={TrainingMicrosoftOffice} />
            <Route exact path="/fr/formation-microsoft-office" component={TrainingMicrosoftOffice} />
            <Route exact path="/en/consulting-services" component={ConsultingCenter} />
            <Route exact path="/fr/services-conseil" component={ConsultingCenter} />
            <Route exact path="/en/terms-of-use" component={TermsOfUse} />
            <Route exact path="/fr/politique-utilisation" component={TermsOfUse} />
            <Route exact path="/en/privacy-statements" component={PrivacyStatements} />
            <Route exact path="/fr/politique-donnees" component={PrivacyStatements} />
            <Route exact path="/en/cookie-statements" component={CookieStatements} />
            <Route exact path="/fr/politique-cookies" component={CookieStatements} />
            <Route exact path="/en/support-for-visa" component={SupportForTrainingInGermany} />
            <Route exact path="/fr/aide-pour-visa" component={SupportForTrainingInGermany} />
            <Route exact path="/en/digital-cosmetics" component={SolutionDigitalCosmetics} />
            <Route exact path="/fr/digital-cosmetics" component={SolutionDigitalCosmetics} />
            <Redirect from="*" to="/" />
          </Switch>
        </section>
        <footer>
          <Footer />
          <FooterEnd />
        </footer>
    </Suspense>
  );

};

export default App;