
const GlobalSettings = {
  digitalCosmeticRosa: '#ff9999',
  digitalCosmeticBlue: '#3333cc',
  digitalCosmeticBlack: '#000000',
  mainWhiteColor: '#f9fafb', /* #f5f5f5*/
  surrogateWhiteColor: '#e6e6e6',
  mainYellowColor: '#ff9900',
  mainDarkColor: '#4d4d4d',
  surrogateDarkColor: '#444444', /*#454542 */
  paddingAroundHTMLElements: '20px',
  marginAroundHTMLElements: '10px',
  spaceAroundHTMLElements: '20px',
};

export default GlobalSettings; 

